import React from "react"
import styled from "styled-components"
import { Reveal } from "react-reveal"
import {graphql, useStaticQuery} from "gatsby"
import { LocalizationContext } from "../../layout/layout"

const StyledHeader = styled.h1`
  font-size: 43px;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.mainNavyBlue};
  text-align: left;
  width: 80%;
  margin: 50px 0;
  @media(min-width: 768px){
    font-size: 89px;
    line-height: 86px;
    margin: 60px 0 100px;
  }
`

const ContentWrapper = styled.div`
  display: block;
  position: relative;
`

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px 20px 0;
  @media(min-width: 768px){
    width: 20%;
    padding-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const StyledP = styled.p`
  color: ${({ theme }) => theme.colors.mainTextNavyBlue};
  font-size: 16px;
  position: relative;
  @media(min-width: 768px) {
    font-size:14px;
  }
  &.client-name{
    padding: 20px 0;
  }
  &.client-header{
    color: ${({ theme }) => theme.colors.mainTextNavyBlue};
    font-weight: bold;
    line-height: 30px;
    display: block;
    ::after{
       content:'';
       position: absolute;
       width: 27px;
       left: 0;
       top: 25px;
       height: 5px;
       border-radius: 3px;
       background-color: ${({ theme }) => theme.colors.componentsBlockGreen};
  }
`

const StyledImage = styled.img`
  width: 100px;
  text-align: left;
`

const CaseStudiesSingleTitle = ({ data, globalData, localize }) => {
  const title = data.title
  const clientName = data.archeeCaseStudyWizytowka[0].klient[0].klienci[0].nazwaKlienta
  const clientLogo = data.archeeCaseStudyWizytowka[0].klient[0].klienci[0].logoKlienta[0].url
  const globalDependsOnLang = localize.lang === "pl" ? globalData.pl.nodes?.[0] || "" :  globalData.en.nodes?.[0] || ""
  const client = globalDependsOnLang.archeeSlowoKlient || ""

  return (
    <div>
      <Reveal effect="fadeInUp">
        <StyledHeader>{title}</StyledHeader>
      </Reveal>
      <ContentWrapper>
        <InfoWrapper>
          <Reveal effect="fadeInUp">
            <StyledP className={"client-header"}>{client}:</StyledP>
          </Reveal>
          <Reveal effect="fadeInUp">
            <StyledP className={"client-name"}>{clientName}</StyledP>
          </Reveal>
          <Reveal effect="fadeInUp">
            <StyledImage src={clientLogo}/>
          </Reveal>
        </InfoWrapper>
      </ContentWrapper>
    </div>
  )
}

const LocalizedCaseStudiesSingleTitle = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query{
      pl: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeSlowoKlient
        }
      }
      en: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeSlowoKlient
        }
      }
    }`);
  return (
      <LocalizationContext.Consumer>
        {localize => <CaseStudiesSingleTitle {...props} globalData={data} localize={localize} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default LocalizedCaseStudiesSingleTitle;

