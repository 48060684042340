import React from "react"
import styled from "styled-components";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import { Reveal } from "react-reveal"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ul {
    li {
      color:  ${({ theme }) => theme.colors.mainNavyBlue};
      list-style-type: disc;
      padding-left: 10px;
    }
  padding: 20px;
  }
  a {
    text-decoration: none;
    color:  ${({ theme }) => theme.colors.mainNavyBlue};
  }
  @media(min-width: 768px) {
    width: 80%;
    padding-right: 5%;
  }
  @media(min-width: 992px){
    padding-right: 15%;
  }  
`;

const LeftWrapper = styled.div`
  width: 0;
  display: flex;
  flex-direction: column;
  @media(min-width: 768px) {
    width: 20%;
  }
`;

const StyledDivOverPicture = styled.div`
  color: ${({theme}) => theme.colors.mainTextDarkGrey};
  font-size: 18px;
  line-height: 30px;
  position: relative;
  @media(min-width: 768px){
    font-size: 20px;
  }
`;

const CaseStudiesSingleDescription = ({data}) => {
  const opis = data.opis
    return (
        <ContentWrapper>
            <LeftWrapper/>
            <RightWrapper>
              <Reveal effect="fadeInUp">
                <StyledDivOverPicture dangerouslySetInnerHTML={getHtmlFromRedactorField(opis)}/>
              </Reveal>
            </RightWrapper>
        </ContentWrapper>
    );
}

export default CaseStudiesSingleDescription
