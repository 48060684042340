import React from "react"
import CaseStudiesSingleImage from "./caseStudiesSingleImage";
import CaseStudiesWideImage from "./caseStudiesWideImage";
import CaseStudiesSingleHeader from "./caseStudiesSingleHeader";
import CaseStudiesSingleDescription from "./caseStudiesSingleDescription";
import CaseStudiesSingleSeeAlso from "./caseStudiesSingleSeeAlso";
import CaseStudiesSingleCheckOtherHeader from "./caseStudiesSingleCheckOtherHeader"
import GraphicModules from "../graphicModules"
import Margin from "../extendedContentComponnets/margin/margin"

const CaseStudiesExtendedContent = (props) => {
    const {data} = props || {};
    return (
        data.map((block, i) => {
            switch (block.__typename) {
                case "Craft_archeeTresc_naglowek_BlockType":
                    return <CaseStudiesSingleHeader key={i} data={block}/>
                case "Craft_archeeTresc_naglowek2_BlockType":
                    return <CaseStudiesSingleCheckOtherHeader key={i} data={block}/>
                case "Craft_archeeTresc_opis_BlockType":
                    return <CaseStudiesSingleDescription key={i} data={block}/>
                case "Craft_archeeTresc_zdjecieSzerokie_BlockType":
                    return <CaseStudiesWideImage key={i} data={block}/>
                case "Craft_archeeTresc_zdjecie_BlockType":
                    return <CaseStudiesSingleImage key={i} data={block}/>
                case "Craft_archeeTresc_margines_BlockType":
                    return <Margin key={ i } data={ block }/>
                case "Craft_archeeTresc_zobaczRowniez_BlockType":
                    return <CaseStudiesSingleSeeAlso key={i} data={block}/>
                case "Craft_archeeTresc_modulGraficzny_BlockType":
                  return <GraphicModules key={ i } data={ block.wybierzModulGraficzny }/>
                default:
                    return <h1 key={i}>Brak obsługi dla {block.__typename}</h1>
            }

        })
    )
}

export default CaseStudiesExtendedContent
